import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MonitoringService} from '../../services/monitoring.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnInit {
  // endpoint = environment.monitoringEndpoint;
  //
  // fetchData(projectName, chain): any[] {
  //   return [
  //     {name: "Whales", endpoint: `/${projectName}?chain=${chain}&addresses=whales`},
  //     {name: "Treasury Liquid", endpoint: `/${projectName}?chain=${chain}&addresses=treasury_liquid`},
  //     {name: "Treasury Vested", endpoint: `/${projectName}?chain=${chain}&addresses=treasury_vested`},
  //     {name: "Exchanges", endpoint: `/${projectName}?chain=${chain}&addresses=exchanges`},
  //     {name: "Infrastructure", endpoint: `/${projectName}?chain=${chain}&addresses=infrastructure`},
  //
  //   ]
  // }
  // baskets = [];
  // totalMonitored = 0;
  addressFilter: string;
  constructor(private http: HttpClient, public monitoringService: MonitoringService) {
    // this.baskets = this.fetchData("om", "ETH");
    // this.baskets.forEach((basket) => {
    //   this.http.get(`${this.endpoint}/balances${basket.endpoint}`).subscribe((data: any) => {
    //     this.totalMonitored += data.amount;
    //     basket.data = data;
    //     console.log(basket, "Yes")
    //   });
    // })
  }

  ngBeforeInit() {

  }

  ngOnInit(): void {

  }

  getUrl() {
    switch (this.monitoringService.currentNetwork) {
      case 'ETH':
        return 'https://etherscan.io/token/0x3593d125a4f7849a1b059e64f4517a86dd60c95d?a='
      case 'BSC':
        return 'https://bscscan.com/token/0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2?a=';
      case 'POLYGON':
        return 'https://polygonscan.com/token/0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea?a=';
      case 'OM_MAINNET':
        return 'https://www.mintscan.io/mantra/address/';
    }
  }
}
